<template>
  <keep-alive :include="keepAliveConfig">
    <router-view style="padding-bottom: 100px" />
  </keep-alive>
</template>

<script>
import { defineComponent, computed } from 'vue'
import store from '@/store'
import { get } from 'lodash'

export default defineComponent({
  name: 'RouterViewWrapper',
  setup (props) {
    const keepAliveConfig = computed(() => {
      return get(store.state, 'app.keepAlive')
    })
    return { keepAliveConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
